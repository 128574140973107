<template>
  <BtnTemplate
    class="ghost"
    :class="{ bordered, small }"
    @click="$emit('click', $event)"
  >
    <span class="btnText">
      <slot />
    </span>
  </BtnTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import BtnTemplate from './template.vue'

export default defineComponent({
  components: {
    BtnTemplate,
  },
  props: {
    bordered: {
      required: false,
      default: false,
      type: Boolean,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
.btnText {
  @apply tw-text-orange-500;
}

.ghost {
  @apply tw-border tw-border-solid tw-border-transparent tw-bg-transparent;

  &:hover {
    @apply tw-border-orange-100 tw-bg-orange-100 tw-shadow-round;
  }

  &.bordered {
    @apply tw-border-orange-500;
  }
}
</style>
